/* stylelint-disable */
@font-face {
  font-family: 'outboundTT';
  src: url('../fonts/outboundTT-Regular.eot');
  src: url('../fonts/outboundTT-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/outboundTT-Regular.woff2') format('woff2'),
      url('../fonts/outboundTT-Regular.woff') format('woff'),
      url('../fonts/outboundTT-Regular.ttf') format('truetype'),
      url('../fonts/outboundTT-Regular.svg#outboundTT-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded Italic';
  src: url('../fonts/GothamRounded-BoldItalic.eot');
  src: url('../fonts/GothamRounded-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/GothamRounded-BoldItalic.woff2') format('woff2'),
      url('../fonts/GothamRounded-BoldItalic.woff') format('woff'),
      url('../fonts/GothamRounded-BoldItalic.ttf') format('truetype'),
      url('../fonts/GothamRounded-BoldItalic.svg#GothamRounded-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded';
  src: url('../fonts/GothamRounded-Medium.eot');
  src: url('../fonts/GothamRounded-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/GothamRounded-Medium.woff2') format('woff2'),
      url('../fonts/GothamRounded-Medium.woff') format('woff'),
      url('../fonts/GothamRounded-Medium.ttf') format('truetype'),
      url('../fonts/GothamRounded-Medium.svg#GothamRounded-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded Book';
  src: url('../fonts/GothamRounded-Book.eot');
  src: url('../fonts/GothamRounded-Book.eot?#iefix') format('embedded-opentype'),
      url('../fonts/GothamRounded-Book.woff2') format('woff2'),
      url('../fonts/GothamRounded-Book.woff') format('woff'),
      url('../fonts/GothamRounded-Book.ttf') format('truetype'),
      url('../fonts/GothamRounded-Book.svg#GothamRounded-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded Light';
  src: url('../fonts/GothamRounded-Light.eot');
  src: url('../fonts/GothamRounded-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/GothamRounded-Light.woff2') format('woff2'),
      url('../fonts/GothamRounded-Light.woff') format('woff'),
      url('../fonts/GothamRounded-Light.ttf') format('truetype'),
      url('../fonts/GothamRounded-Light.svg#GothamRounded-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham Rounded Bold';
  src: url('../fonts/GothamRounded-Bold.eot');
  src: url('../fonts/GothamRounded-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/GothamRounded-Bold.woff2') format('woff2'),
      url('../fonts/GothamRounded-Bold.woff') format('woff'),
      url('../fonts/GothamRounded-Bold.ttf') format('truetype'),
      url('../fonts/GothamRounded-Bold.svg#GothamRounded-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* stylelint-enable */

form {
  font-family: "Gotham Rounded", arial, sans-serif;

  label {
    display: block;
    padding-left: 15px;
    font-size: 1.375em;
    line-height: 2em;
  }

  input[type="text"] {
    border: 2px solid $ttgrey;
    font-size: 1.375em;
    display: block;
    width: 100%;
    line-height: 2em;
    padding: 0 15px;
    margin: 0 0 0.75em 0;
    color: $ttgrey;

    @include border-radius(9px);

    &::-webkit-input-placeholder {
      color: grey;
    }

    &::-ms-input-placeholder {
      color: grey;
    }

    &::placeholder {
      color: grey;
    }

    &:focus {
      border-color: $gold;
      outline: none;
    }
  }

  textarea {
    border: 2px solid $ttgrey;
    font-size: 1.375em;
    display: block;
    width: 100%;
    height: 250px;
    line-height: 2em;
    padding: 0 15px;
    margin: 0 0 0.75em 0;
    color: $ttgrey;
    font-family: "Gotham Rounded", arial, sans-serif;

    @include border-radius(9px);
  }

  .row--terms {
    position: relative;
    margin: 0 0 2em 0;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      width: 25px;
      height: 25px;
      left: 1px;
      top: 8px;
      cursor: pointer;
      z-index: 1;

      + label {
        display: inline-block;
        position: relative;
        padding-left: 35px;

        &::before {
          content: "\f00c";
          display: block;
          width: 26px;
          font-size: 1em;
          line-height: 1em;
          text-align: center;
          border: 2px solid $ttgrey;
          position: absolute;
          color: $white;
          left: 0;
          top: 10px;
          font-family: "Font Awesome 6 Free", Arial, Helvetica, sans-serif;
          font-weight: 900;

          @include border-radius(2px);
        }
      }

      &:checked + label {
        &::before {
          color: $ttgrey;
          background: $darkGold;
          border-color: $darkGold;
        }
      }
    }
  }

  button {
    background: $ttgrey;
    color: $white;
    border: 3px solid $ttgrey;
    width: 147px;
    text-align: center;
    font-size: 1.25em;
    line-height: 2em;
    font-family: "Gotham Rounded Bold", Arial, Helvetica, sans-serif;
    text-transform: uppercase;

    @include border-radius(11px);
    @include transition(250ms);

    &:hover {
      cursor: pointer;
      background: $white;
      color: $gold;
    }
  }

  .row {
    position: relative;

    span {
      display: none;
      position: absolute;
      left: 0;
      bottom: -22px;
      color: red;

      &.show {
        display: block;
      }
    }

    &.error {
      input {
        border-color: red;
      }

      textarea {
        border-color: red;
      }

      label {
        &::before {
          border-color: red !important;
        }
      }

      span {
        display: block;
      }
    }
  }
}

// additional styles for recaptcha

.recaptcha-message {
  margin-bottom: 30px;

  a {
    color: #e6c183;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.site-header {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  background: $ttgrey;

  @include respond-to-max("small-lap") {
    padding: 0 15px;
  }

  @include respond-to-max("ipAirPort") {
    padding: 20px 0;
  }
}

.site-header__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.site-header__burger {
  margin-left: 25px;
  cursor: pointer;
  height: 50%;

  span {
    position: relative;
    display: block;
    width: 35px;
    height: 2px;
    background: $white;
    border-radius: 20px;
    transition: 0.3s all ease-in-out;
    top: 50%;

    &::before,
    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 35px;
      height: 2px;
      border-radius: 20px;
      background: $white;
      top: -12px;
      transition: 0.3s all ease-in-out;
    }

    &::after {
      top: 12px;
    }
  }

  &.active {
    span {
      background-color: transparent;

      &::before,
      &::after {
        transform: rotate(45deg);
        top: 0;
      }

      &::after {
        transform: rotate(-45deg);
        top: 0;
      }
    }
  }

  @include respond-to("lg") {
    display: none;
  }

  @include respond-to-max("sm") {
    position: absolute;
  }
}

.site-header__menu {
  display: flex;
  position: relative;

  @include respond-to("lg") {
    flex-grow: 1;
  }

  .main-nav {
    position: fixed;
    background: $ttgrey;
    padding: 0;
    list-style: none;
    width: 90vw;
    left: -100%;

    @include transition(300ms);

    padding: 135px 0 0 0;
    top: 0;
    margin: 0;

    @include respond-to("lg") {
      padding: 0 0 0 0;
    }

    > li {
      background: $white;
      font-size: 1em;
      margin: 0 20px 0 0;
      position: relative;

      @include respond-to("lg") {
        background: transparent;
      }

      &.language-switcher {
        @include respond-to("lg") {
          margin-left: auto;
        }
      }

      a {
        display: block;
        padding: 40px 30px 40px 20px;
        color: $ttgrey;
        white-space: nowrap;
        text-decoration: none;
        font-size: clamp(1.1rem, 5vw, 1.563rem);
        transition: 0.3s all ease-in-out;
        font-family: "Gotham Rounded Bold", Arial, Helvetica, sans-serif;

        &.header-links {
          &--region {
            display: flex;
            text-decoration: none;
            // font-size: 1em;
            font-family: "Gotham Rounded Bold", Arial, Helvetica, sans-serif;

            @include respond-to("lg") {
              color: $white;

              @include flex-center;
            }

            span.current-store-link__title {
              display: block;
              white-space: nowrap;

              &::after,
              &::before {
                display: none;
              }
            }

            img {
              @include border-radius(32px);

              max-width: 35px;
            }

            span {
              margin-left: 10px;
            }
          }
        }
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        @include respond-to("lg") {
          .child-menu {
            display: block;
          }
        }
      }

      ul {
        &.child-menu {
          li {
            a {
              font-size: 0.8em;
              padding: 8px 30px;
              color: $ttgrey;
              position: relative;

              &::before {
                content: "\f054";
                display: block;
                font-family: "Font Awesome 6 free", Arial, Helvetica, sans-serif;
                font-weight: 900;
                position: absolute;
                text-indent: 0;
                color: #e6c183;
                position: absolute;
                right: 15px;
                top: 8px;
                font-size: 1em;
              }
            }
          }

          @media screen and ( max-height: 750px ) {
            max-height: 80vh;
            overflow: scroll;
          }
        }
      }
    }

    > span {
      position: absolute;
      display: block;
      float: right;
      width: 35px;
      height: 2px;
      background: $white;
      border-radius: 20px;
      transition: 0.3s all ease-in-out;
      background-color: transparent;
      top: 65px;
      right: 20px;

      &::before,
      &::after {
        position: absolute;
        content: "";
        display: block;
        width: 35px;
        height: 2px;
        border-radius: 20px;
        background: $white;
        transition: 0.3s all ease-in-out;
        transform: rotate(45deg);
        top: 0;
      }

      &::after {
        transform: rotate(-45deg);
        top: 0;
      }

      @include respond-to("lg") {
        display: none;
      }
    }

    &.active {
      left: 0;
    }

    @include respond-to("lg") {
      position: relative;
      display: flex;
      left: 0;
      top: 0;
      width: 100%;
      background: transparent;

      li {
        a {
          color: $white;

          &:hover {
            color: pink;
          }
        }
      }
    }

    @include respond-to-max("ipAirPort") {
      width: 100vw;
      background: none;
      height: 100%;
      overflow: scroll;

      li {
        margin: 0;
        width: 90vw;

        a {
          padding: 8px 30px 8px 20px;

          &::before {
            content: "\f054";
            display: block;
            font-family: "Font Awesome 6 free", Arial, Helvetica, sans-serif;
            font-weight: 900;
            position: absolute;
            text-indent: 0;
            color: #e6c183;
            position: absolute;
            right: 15px;
            top: 8px;
            font-size: 1em;
          }
        }

        &.item-has-child {
          a {
            .child-chevron {
              display: none;
            }
          }

          .child-menu {
            position: relative;
            width: 100%;

            li {
              a {
                font-size: 1.2em;
                padding: 8px 30px 8px 20px;
              }
            }
          }

          &.active {
            a {
              &::before {
                -moz-transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                -o-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                transform: rotate(90deg);
              }
            }

            .child-menu {
              display: block;

              li {
                a {
                  &::before {
                    -moz-transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    -ms-transform: rotate(0deg);
                    transform: rotate(0deg);
                  }
                }
              }
            }
          }
        }
      }
    }

    @include respond-to-max("ipAirPort") {
      &::before {
        content: "";
        display: block;
        position: fixed;
        left: -100%;
        top: 0;
        background: $ttgrey;
        width: 100%;
        height: 135px;

        @include transition(300ms);
      }

      &.active {
        &::before {
          left: 0;
        }
      }
    }
  }
}

.site-header__logo {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 2em 0 0;

  svg {
    width: 103px;
    height: auto;
  }

  @include respond-to("lg") {
    flex-grow: 0;
  }
}

// Logic for menu items with children

.item-has-child {
  position: relative;

  &.active {
    > .child-chevron {
      &::before {
        content: "\f078";
      }
    }
  }

  @include respond-to("md") {
    &.hover {
      > .child-menu {
        display: block;
        position: absolute;
        content: "";
        width: 115%;
        list-style: none;
        left: 0;
        padding: 0;
        // overflow: scroll;

        li {
          a {
            color: $ttgrey;
          }

          .child-menu {
            position: relative;
            margin: 0;

            li {
              padding: 0 0 10px;

              a {
                padding: 10px 20px;
                width: 100%;
                position: relative;
              }
            }
          }
        }
      }
    }
  }

  .child-chevron {
    position: absolute;
    top: 35%;
    right: -5px;

    // @include respond-to("md") {
    //   top: 12px;
    //   right: unset;
    // }

    &::before {
      font-family: "Font Awesome 6 free", Arial, Helvetica, sans-serif;
      content: "\f054";
      font-weight: 900;
      display: block;
      position: relative;
      text-indent: 0;
      color: $ttyellow;
      float: right;

      @include respond-to("md") {
        content: "\f078";
      }
    }
  }
}

.child-menu {
  margin: 10px 0;
  position: absolute;
  display: none;
  width: 200%;
  padding: 0;
  margin: 0;

  &.active {
    display: block;

    li {
      font-size: 1em;

      .child-chevron {
        right: -50px;
      }
    }
  }
}

//classes for menu item colours

.child-menu {
  .menu-bottle {
    background: $bottleFeeding;
  }

  .menu-sleeping {
    background: $sleeping;
  }

  .menu-breast-feeding {
    background: $breastFeeding;
  }

  .menu-breast-feeding-alt {
    background: $breastFeedingAlt;
  }

  .menu-sterilising {
    background: $sterilising;
  }

  .menu-nappy {
    background: $nappyDisposal;
  }

  .menu-soothing {
    background: $soothing;
  }

  .menu-healthcare {
    background: $healthcare;
  }

  .menu-cups {
    background: $cupsAndFeeding;
  }
}

// styles for breadcrumbs

.breadcrumb--container {
  position: absolute;
  top: 115px;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  padding: 15px 0;
  z-index: 1;

  .breadcrumbs {
    ul {
      display: flex;
      align-items: center;

      li {
        margin: 0;

        a {
          color: $ttgrey;
          text-decoration: none;
          display: block;
          font-size: 0.875em;
          line-height: 1em;
          padding: 0 20px;
          position: relative;

          @include respond-to-max("sm") {
            padding: 0 5px;
            font-size: 0.6em;
          }
        }

        &:first-of-type {
          a {
            padding-left: 0;
          }
        }
      }
    }

    .break-chevron {
      margin: 0 10px;

      &::before {
        font-family: "Font Awesome 6 free", Arial, Helvetica, sans-serif;
        content: "\f054";
        font-weight: 900;
        display: block;
      }

      @include respond-to-max("sm") {
        margin: 0 2px;
        font-size: 0.8em;
      }
    }
  }

  @include respond-to-max("small-lap") {
    padding: 15px 10px;
  }

  @include respond-to-max("ipAirLand") {
    padding: 15px 10px;
  }

  @include respond-to-max("ipAirPort") {
    top: 137px;
  }

  @include respond-to-max("md") {
    top: 137px;
  }
}

.lang-hong-kong-english {
  .site-header__menu {
    .main-nav {
      > li {
        @include respond-to-max("lg+") {
          margin-right: 10px;
        }

        a {
          @include respond-to-max("surface") {
            font-size: clamp(1rem, 5vw, 1.363rem);
            padding-left: 10px;
          }

          @include respond-to-max("xl") {
            font-size: clamp(1rem, 5vw, 1.1rem);
            padding-left: 10px;
            padding-right: 15px;
          }

          @include respond-to-max("lg+") {
            font-size: clamp(1rem, 5vw, 1rem);
            padding-left: 10px;
            padding-right: 15px;
          }

          @include respond-to-max("md") {
            font-size: clamp(1.1rem, 5vw, 1.563rem);
          }
        }
      }
    }
  }
}

.contentBlock {
  &__textBlock {
    padding: 3em 0;

    img {
      display: block;

      &.fr-fil {
        float: left;
        margin: 10px 20px 10px 0;
      }

      &.fr-fir {
        float: right;
        margin: 10px 0 10px 20px;
      }
    }

    table {
      width: 100%;
      margin-bottom: 20px;

      thead {
        tr {
          th {
            background: $ttgrey;
            color: $white;
            padding: 10px;
            width: 20%;
            text-align: left;
            border-right: 2px solid $white;

            &:last-of-type {
              border-right: 0;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            color: $ttgrey;
            padding: 10px;
            width: 20%;
            text-align: left;
            border-right: 2px solid $white;

            &:last-of-type {
              border-right: 0;
            }
          }
        }

        tr:nth-child(even) {
          background: $white;
        }

        tr:nth-child(odd) {
          background: $pageBG;
        }
      }
    }
  }
}

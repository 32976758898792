.footer {
  background: $ttgrey;
  color: $white;
  padding: 2rem 0;
  position: relative;
  z-index: 1;

  .container {
    width: 100%;
    max-width: $max-width-containers;
    margin: 0 auto;

    .footer__social-networks {
      margin: 2em auto;
      max-width: 350px;
      width: 100%;
      flex-wrap: wrap;

      @include flex-center;

      h4 {
        text-align: center;
        margin: 0 0 1.5em 0;
        flex: 0 0 100%;
        font-size: 1.5em;

        @include respond-to-max("sm") {
          font-size: 1.25em;
        }
      }

      ul {
        flex: 0 0 100%;

        @include flex-center;

        li {
          list-style: none;
          margin: 0 30px 0 0;

          a {
            color: $white;
            display: block;
            position: relative;
            width: 50px;
            height: 50px;
            border: 2px solid $white;
            font-size: 1em;
            text-align: center;
            text-decoration: none;

            @include transition(250ms);
            @include border-radius(50%);
            @include flex-center;

            &::before {
              font-family: "Font Awesome 6 Brands", Arial, Helvetica, sans-serif;
              font-weight: 400;
              display: block;
              position: absolute;
              margin: auto;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-indent: 0;
            }

            &:hover {
              background: $white;
              color: $ttgrey;
            }

            @include respond-to-max("md") {
              width: 40px;
              height: 40px;
              font-size: 0.9em;
            }
          }

          &.social-youTube {
            a {
              &::before {
                content: "\f167";
              }
            }
          }

          &.social-instagram {
            a {
              &::before {
                content: "\f16d";
              }
            }
          }

          &.social-twitter {
            a {
              &::before {
                content: "\f099";
              }
            }
          }

          &.social-facebook {
            a {
              &::before {
                content: "\f39e";
              }
            }
          }

          &.social-pintrest {
            a {
              &::before {
                content: "\f231";
              }
            }
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .footer__links {
      @include flex-center;

      justify-content: center;

      ul {
        @include flex-center;

        li {
          margin: 0 30px 0 0;
          list-style: none;
          font-size: 1.2em;

          a {
            color: $white;
            text-decoration: none;
            display: block;
            font-family: "Gotham Rounded Bold", Arial, Helvetica, sans-serif;
          }
        }
      }

      &--region {
        color: $white;
        text-decoration: none;
        font-size: 1em;
        font-family: "Gotham Rounded Bold", Arial, Helvetica, sans-serif;

        @include flex-center;

        img {
          @include border-radius(32px);

          max-width: 30px;
        }

        span {
          margin-left: 10px;
          white-space: nowrap;
        }
      }

      @include respond-to-max("md") {
        margin: 0;

        ul {
          display: block;

          li {
            margin: 0 0 1em 0;
            text-align: center;
            font-size: 1em;

            a {
              line-height: 2em;
            }
          }
        }
      }

      @include respond-to-max("md") {
        display: block;
      }
    }

    .footer-secondary {
      padding: 2em 0;
      text-align: center;

      p {
        font-size: 0.875em;
      }
    }

    @include respond-to-max("md") {
      padding: 0 15px;

      .footer-secondary {
        padding: 4em 0 2em 0;
      }
    }
  }
}

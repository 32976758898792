.contentBlock {
  &__contact {
    padding: 4em 0;

    .container {
      @include flex-start;

      align-items: flex-start;

      .half-width {
        flex: 0 0 50%;
        padding: 0 15px;

        &.alignCenter {
          text-align: center;
        }

        hr {
          border-color: $ttgrey;
        }
      }

      @include respond-to-max("sm") {
        display: block;

        .half-width {
          flex: 0 0 100%;
        }
      }
    }
  }
}

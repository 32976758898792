// mixins

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin fa-icon {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
}

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

// generic transform
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

//Border radius
@mixin border-radius($tl:null, $tr:null, $br:null, $bl:null) {
  -webkit-border-radius: $tl $tr $br $bl;
  border-radius: $tl $tr $br $bl;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media ( min-width: $value ) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin respond-to-max($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    $value: $value - 1;

    @media ( max-width: $value ) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin respond-between($breakpoint1, $breakpoint2) {
  // Retrieves the value from the key
  $breakpoint-min: map-get($breakpoints, $breakpoint1);
  $breakpoint-max: map-get($breakpoints, $breakpoint2);

  // If the key exists in the map
  @if $breakpoint-min != null and $breakpoint-max != null {
    // Prints a media query based on the value
    $breakpoint-max: $breakpoint-max - 1;

    @media ( min-width: $breakpoint-min ) and ( max-width: $breakpoint-max ) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "Please make sure it is defined in `$breakpoints` map.";
  }
}

// Loading fonts mixin
@mixin font-face($name, $file) {
  @font-face {
    font-family: "#{$name}";
    src: url("../assets/fonts/#{$file}/#{$file}.eot");
    src:
      url("../assets/fonts/#{$file}/#{$file}.eot?#iefix") format("embedded-opentype"),
      url("../assets/fonts/#{$file}/#{$file}.woff") format("woff"),
      url("../assets/fonts/#{$file}/#{$file}.ttf") format("truetype"),
      url("../assets/fonts/#{$file}.svg?#webfont") format("svg");
  }
}

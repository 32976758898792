.contentBlock {
  &__bg {
    background: $bottleFeeding;
    overflow: hidden;

    @include transition(250ms);

    @each $name, $BGcolor in $categoryColours {
      &--#{$name} {
        background: $BGcolor;

        &.contentBlock__fullWidth--shallow,
        &.contentBlock__flow {
          background: darken($BGcolor, 14.5);
        }
      }
    }

    .background {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      svg {
        width: 100%;
        height: auto;

        path {
          fill: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
}

.hero {
  + div,
  + section {
    overflow: visible;

    &.contentBlock__bg {
      &--bottleFeeding {
        .background {
          svg {
            path {
              fill: $ttyellow;
            }
          }
        }
      }

      @each $name, $BGcolor in $categoryColours {
        &--#{$name} {
          .background {
            svg {
              path {
                fill: darken($BGcolor, 7.5);
              }
            }
          }
        }
      }
    }

    &.contentBlock__fullWidth {
      padding-top: 250px;

      &.contentBlock__fullWidth--lp {
        padding-top: 50px;
        overflow: hidden;

        .background {
          svg {
            top: -100%;

            path {
              fill: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
    }
  }
}

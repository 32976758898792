.btn {
  background: $ttgrey;
  display: inline-block;
  padding: 7px 20px;
  line-height: 1.5em;
  color: $white;
  text-decoration: none;
  font-size: 1.25em;
  border: 3px solid $ttgrey;
  text-transform: uppercase;
  margin: 0 auto;
  margin-top: 2em;
  font-family: "Gotham Rounded Bold", arial, sans-serif;
  text-align: center;

  @include border-radius(11px);
  @include transition(250ms);

  &:hover {
    background: $white;
    color: $gold;
  }

  &__plain {
    background: none;
    color: $ttgrey;
    border: none;
    font-family: "Gotham Rounded", arial, sans-serif;
    position: relative;

    .icon {
      position: absolute;
      right: 0;
      top: 7px;
      font-size: 0.85em;

      @include transition(250ms);
    }

    &:hover {
      background: none;
      color: inherit;

      .icon {
        right: -5px;
      }
    }
  }
}

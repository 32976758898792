.contentBlock {
  &--video {
    position: relative;
    overflow: hidden;
    padding: 6em 0;

    .background {
      svg {
        width: 150%;

        @include transform(translateX(-25%) translateY(-25%));
      }
    }

    .container {
      .video-text {
        text-align: center;
      }

      .video {
        padding-bottom: 56.25%;
        position: relative;

        &__overlay {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 2;

          .video__thumb {
            img {
              width: 100%;
              height: auto;
            }
          }

          .video__play {
            width: 175px;
            height: 175px;
            position: absolute;
            margin: auto;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.7);

            @include border-radius(100%);

            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 5em;
          }

          &.active {
            display: none;

            + .videoWrapper {
              iframe {
                display: block;
              }
            }
          }

          @include respond-to-max("ipAirLand") {
            display: none;
          }
        }

        iframe {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          display: none;

          @include respond-to-max("ipAirLand") {
            display: block;
          }
        }
      }
    }
  }
}

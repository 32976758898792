.contentBlock {
  &__guidlinesListing {
    padding: 4em 0;
    text-align: center;

    .rangeListing {
      @include flex-center;

      flex-wrap: wrap;
      align-items: flex-start;

      &__item {
        width: 25%;
        padding: 0 15px;

        .item__img {
          margin: 0 0 1em 0;

          img {
            width: 100%;
            max-width: 225px;
            height: auto;
            margin: 0 auto;
          }
        }

        .item__text {
          text-align: center;

          p {
            line-height: 1.2em;
            margin: 0 0 1em 0;
          }
        }

        @include respond-to-max("md") {
          width: 100%;
        }
      }
    }
  }
}

.contentBlock {
  &__rangeListing {
    padding-bottom: 2em;
    background: $white;

    .contentBlock__intro {
      background: none;
      padding: 2em 0;
      text-align: center;
    }

    .rangeListing {
      .container {
        @include flex-start;

        flex-wrap: wrap;
        align-items: stretch;

        @include respond-to-max("md") {
          display: block;
        }
      }

      &__item {
        width: 48%;
        margin: 1%;
        padding: 2em;
        text-align: center;
        position: relative;
        background: $white;

        @include border-radius(50px);

        .item__text {
          position: relative;
          z-index: 1;

          .text--block {
            width: 90%;
            margin: 0 auto;

            @include respond-to-max("ipAirLand") {
              width: 100%;
            }
          }

          h3 {
            font-size: 2em;
            line-height: 1.4em;
            font-family: "Gotham Rounded Bold", arial, sans-serif;
            margin: 0 0 0.5em 0;
          }

          p {
            font-family: "Gotham Rounded Light", arial, sans-serif;
            line-height: 1.4em;
            margin: 0 0 0.5em 0;
          }
        }

        .item__img {
          @include transition(250ms);

          position: relative;
          z-index: 1;
          width: 100%;
          height: 500px;
          margin: 0 auto;
          background-size: 75%;
          background-position: center;
          background-repeat: no-repeat;
        }

        .item__button {
          position: absolute;
          z-index: 1;
          bottom: -20px;
          left: 0;
          width: 100%;

          .btn {
            margin: 0;
          }
        }

        &:hover {
          cursor: pointer;

          .item__img {
            background-size: 80%;
          }

          @each $name, $BGcolor in $categoryColours {
            .contentBlock__bg--#{$name} {
              background: darken($BGcolor, 5%);
            }
          }
        }

        @include respond-to-max("md") {
          width: 100%;
          margin-bottom: 5em;

          .item__img {
            width: 100%;
          }
        }

        .rangeListing__bg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 50px;
          z-index: 0;

          @include transition(250ms);
        }
      }
    }
  }

  &__bg {
    @each $name, $BGcolor in $categoryColours {
      &--#{$name} {
        .background {
          svg {
            path {
              fill: rgba(255, 255, 255, 0.5);
            }
          }
        }
      }
    }
  }
}

.contentBlock__rangeListing {
  &--alt {
    @each $name, $BGcolor in $categoryColours {
      &.contentBlock__bg--#{$name} {
        background: $BGcolor;
      }
    }

    .rangeListing {
      .container {
        justify-content: center;
      }

      &__item {
        width: 31.33%;

        @each $name, $BGcolor in $categoryColours {
          .contentBlock__bg--#{$name} {
            background: lighten($BGcolor, 5%);
          }
        }

        .item__img {
          height: 270px;
          margin: 0 0 1em 0;

          img {
            width: auto;
            height: 100%;
            margin: 0 auto;
          }
        }

        .item__text {
          h3 {
            font-family: "Gotham Rounded", arial, sans-serif;
          }
        }

        @include respond-to-max("md") {
          width: 100%;
          margin: 3em 0;

          .item__img {
            height: auto;

            img {
              width: auto;
              height: 100%;
              max-height: 250px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

.contentBlock {
  &__accordian {
    padding: 0 0 3em 0;
  }
}

.accordian {
  .text__dropdown {
    padding: 2em 4em;
    border-bottom: 2px solid $ttgrey;

    &--heading {
      position: relative;

      h4 {
        margin: 0;
      }

      .dropdown--icon {
        position: absolute;
        right: 0;
        top: -5px;
        font-size: 3em;
        color: $darkGold;

        @include transform(rotate(0deg));
        @include transition(250ms);
      }
    }

    &--text {
      display: none;
      padding: 4em 0;

      h3 {
        font-size: 2em;
        font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
        margin: 0 0 1em 0;

        + p {
          font-family: "Gotham Rounded Bold", Arial, Helvetica, sans-serif;
        }
      }

      ul,
      ol {
        li {
          font-family: "Gotham Rounded ", Arial, Helvetica, sans-serif;
        }
      }

      p {
        margin: 0 0 1em 0;

        img {
          max-width: 100%;
          height: auto;
        }
      }

      img {
        &.image {
          max-width: 40%;
          height: auto;

          &-float-right {
            float: right;
            margin: 1em 0 1em 1em;
          }

          &-float-left {
            float: left;
            margin: 1em 1em 1em 0;
          }

          &-float-center {
            margin: 1em auto;
          }
        }
      }

      table {
        tr {
          color: $ttgrey;

          &:first-of-type {
            background: $ttgrey;
            color: $white;
          }

          &:nth-child(even) {
            background: #e6dfd2;
          }

          td {
            padding: 10px;
            border-right: 2px solid white;

            p {
              margin: 0;
            }

            img {
              display: inline-flex;

              @include respond-to-max("sm") {
                display: block;
                margin: 0 auto;
                margin-bottom: 5px;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }

    &.active {
      .text__dropdown--heading {
        .dropdown--icon {
          @include transform(rotate(180deg));
        }
      }

      .text__dropdown--text {
        display: block;
      }
    }

    &:first-of-type {
      border-top: 2px solid $ttgrey;
    }
  }

  .container {
    @include respond-to-max("sm") {
      padding: 0;

      .text__dropdown {
        padding: 2em 1em;

        .text__dropdown--heading {
          padding: 0 50px 0 0;

          .dropdown--icon {
            font-size: 2em;
            top: 0;
          }

          h4 {
            font-size: 1.25em;
          }
        }

        .text__dropdown--text {
          h3 {
            font-size: 1.75em;
            line-height: 1.2em;
          }

          p {
            font-size: 1.125em;
          }
        }
      }
    }
  }
}

// styles for the popup window

.popup {
  position: fixed;
  display: block;
  opacity: 1;
  top: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  z-index: 10;

  @include transition(500ms);

  overflow-y: scroll;

  &.active {
    right: 0;

    .background {
      right: 0;
    }
  }

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;

    @include transition(500ms);
  }

  @each $name, $BGcolor in $categoryColours {
    &.contentBlock__bg--#{$name} {
      .background {
        svg {
          path {
            fill: darken($BGcolor, 2.5);
          }
        }
      }
    }
  }

  &-window {
    height: 100%;

    .background {
      svg {
        // width: 150%;
        // height: 150%;
        // -moz-transform: translateX(-10%) translateY(-10%);
        // -webkit-transform: translateX(-10%) translateY(-10%);
        // -o-transform: translateX(-10%) translateY(-10%);
        // -ms-transform: translateX(-10%) translateY(-10%);
        // transform: translateX(-10%) translateY(-10%);
      }
    }
  }
}

.popup-window {
  position: relative;
  z-index: 1;
}

.popup-content {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 40px;
  padding-top: 40px;

  @include respond-to-max("ipAirPort") {
    flex-flow: column nowrap;
    align-items: center;
  }

  @include respond-to-max("md") {
    margin: 0 20px;
  }

  .popup-content-image {
    max-width: 50%;
    min-width: 50%;

    img {
      max-width: 100%;
      margin: 0 auto;
    }

    @include respond-to-max("xl") {
      padding-right: 15px;
    }
  }

  .popup-content-content {
    max-width: 50%;
    min-width: 50%;

    h4 {
      font-weight: 600;
    }

    .popup-btn {
      &:first-of-type {
        margin-right: 15px;
      }
    }

    @include respond-to-max("xl") {
      min-width: unset;
      max-width: 100%;
    }
  }
}

.popup-content-gallery {
  margin-top: 40px;

  ul {
    width: 100%;

    li {
      width: 25%;

      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  img {
    max-width: 25%;
  }

  @include respond-to-max("md") {
    flex-flow: row wrap;

    img {
      max-width: 50%;

      @include respond-to-max("sm") {
        max-width: 100%;
      }
    }
  }
}

.popup-content-video {
  display: flex;
  justify-content: center;
  margin: 30px 0;

  .videoWrapper {
    max-width: 50%;
    padding-bottom: 28.25%;
    position: relative;
    width: 100%;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  @include respond-to-max("sm") {
    margin: 2em 0;

    .videoWrapper {
      max-width: 90%;
      padding-bottom: 49.25%;
    }
  }
}

.popup-close {
  position: absolute;
  cursor: pointer;
  top: 20px;
  width: 50px;
  height: 50px;
  right: 20px;
  background: $white;
  border-radius: 50%;

  &::before {
    position: relative;
    top: 35%;
    left: 40%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    font-family: "Font Awesome 6 free", Arial, Helvetica, sans-serif;
    content: "\58";
    font-weight: 400;
    display: block;
    color: $ttyellow;
    font-size: 1.7rem;
  }
}

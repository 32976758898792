.retail-block {
  position: relative;
  z-index: 1;
  background: $white;
  padding: 3em;

  @include respond-to-max("md") {
    padding: 2em 15px;
  }
}

.retail-block-image-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.retail-block-image {
  padding: 0 40px;
  background: $grey;
  border-radius: 30px;
  margin: 0 15px 30px;
  max-width: 22%;
  height: 250px;
  display: flex;
  align-items: center;

  img {
    max-width: 100%;
    transition: 0.3s all ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  @include respond-to-max("lg") {
    max-width: 46%;
  }

  @include respond-to-max("md") {
    max-width: 47%;
    padding: 0 15px;
    margin: 0 1.5% 2em 1.5%;
  }
}

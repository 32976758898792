// Colours
$white : #ffffff;
$black : #000000;
$ttgrey: #25303b;
$ttyellow: #e6c183;
$gold  : #e0c38a;
$darkGold: #dba74e;
$grey: #bdc0c4;

// Trans colours
$transWhite: rgba(255, 255, 255, 0.5);

// Product colours
$bottleFeeding: #e6dfd2;
$sleeping: #cce1e0;
$breastFeeding: #f7e7e6;
$breastFeedingAlt: #e5afad;
$sterilising: #c4edef;
$nappyDisposal: #d6e5dc;
$soothing: #bce0cf;
$healthcare: #f7cdca;
$cupsAndFeeding: #e5e7ba;
$pageBG: #fbf6ed;
$aboutBG: #f4e4ca;

$categoryColours:
  "white" $white,
  "bottleFeeding" $bottleFeeding,
  "sleeping" $sleeping,
  "sterilising" $sterilising,
  "nappyDisposal" $nappyDisposal,
  "soothing" $soothing,
  "healthcare" $healthcare,
  "cupsAndFeeding" $cupsAndFeeding,
  "breastfeeding" $breastFeeding,
  "standard" $ttyellow,
  "about" $aboutBG,
  "page" $pageBG;

// flow colours
$variflow: #f9db80;
$slowflow: #bcd78e;
$medflow: #7dcfee;
$fastflow: #f0924a;

// media queries
$breakpoints: (
  "xs" : 321px,
  "sm" : 480px,
  "md" : 768px,
  "ipAirPort" : 821px,
  "mobLand" : 845px,
  "lg" : 1024px,
  "lg+" : 1025px,
  "ipAirLand" : 1181px,
  "xl" : 1200px,
  "small-lap" : 1367px,
  "surface" : 1369px,
) !default;

// colours
$primary-color: #cccccc;

// buttons
$btn-primary: #cccccc;

// layout
$max-width-containers: 1440px !default;

// auto cards
$autocards-ldesk-max-items: 5;
$autocards-desk-max-items: 4;
$autocards-tab-max-items: 3;
$autocards-mob-max-items: 1;

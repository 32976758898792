// Block quote container is in multi column block container.

.block-quote {
  position: relative;
  z-index: 1;
  background: $white;
}

blockquote {
  font-size: 2.85rem;
  color: $darkGold;
  font-weight: 600;

  div {
    position: relative;
    padding: 0 50px;

    &::before,
    &::after {
      content: "\f10d";
      font-family: "Font Awesome 6 free", Arial, Helvetica, sans-serif;
      font-weight: 900;
      display: block;
      position: absolute;
      margin: auto;
      left: 0;
      text-indent: 0;
    }

    &::before {
      top: 0;
    }

    &::after {
      position: relative;
      content: "\f10e";
      display: inline-block;
      font-size: 1.3rem;
      bottom: -12px;
      left: 5px;
    }
  }
}

// Mobile styles for block quotes

.block-quote-two {
  @include respond-to-max("mobLand") {
    max-width: 100%;
    margin: 0 20px 30px;
  }
}

.multi-column-block {
  position: relative;
  width: 100%;
  background: white;
  z-index: 1;
}

.multi-column-container,
.block-quote-container {
  display: flex;
  flex-flow: row wrap;
}

.multi-column-basics,
.block-quote-basics {
  margin-bottom: 20px;
}

.multi-column-one,
.block-quote-one {
  width: 100%;
}

.multi-column-two,
.block-quote-two {
  max-width: 48%;
  margin-right: 20px;

  img {
    width: 100%;
  }
}

.multi-column-three {
  max-width: 31%;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }
}

.multi-column-four {
  max-width: 23%;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }
}

@include respond-to-max("mobLand") {
  .multi-column-one,
  .multi-column-two,
  .multi-column-three,
  .multi-column-four {
    max-width: 100%;
    margin: 10px;
  }
}

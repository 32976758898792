.contentBlock {
  &__categoryListing {
    padding: 3em 0;

    .categoryListing {
      padding: 2em 0;
      flex-wrap: wrap;
      justify-content: stretch;

      @include flex-center;

      &__item {
        flex: 0 0 23%;
        margin: 1%;
        overflow: hidden;
        position: relative;
        align-self: stretch;

        @include border-radius(50px);

        a {
          color: inherit;
          text-decoration: none;
          display: block;
        }

        img {
          width: 100%;
          height: auto;
        }

        &--text {
          padding: 0 10px 15px 10px;

          p {
            font-family: "Gotham Rounded Bold", Arial, Helvetica, sans-serif;
            margin: 0;
            text-align: center;
            text-decoration: none;
          }
        }

        @include respond-to-max("ipAirPort") {
          flex: 0 0 48%;
        }

        @include respond-to-max("sm") {
          @include border-radius(25px);

          flex: 0 0 47%;
          margin: 1% 1.5% 1em 1.5%;

          .categoryListing__item--text {
            p {
              line-height: 1.2em;
            }
          }
        }

        &--img {
          width: 100%;
          height: 300px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 75%;
          margin-bottom: 10px;

          @include respond-to-max("ipAirLand") {
            height: 250px;
          }

          @include respond-to-max("lg+") {
            height: 225px;
          }

          @include respond-to-max("sm") {
            height: 175px;
          }
        }
      }
    }

    .container {
      &--back {
        position: relative;

        .backLink {
          position: absolute;
          left: 15px;
          top: 0;
          font-size: 2em;

          a {
            color: $darkGold;
          }
        }
      }

      &--btn {
        text-align: center;
      }
    }
  }
}

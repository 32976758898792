body {
  &.open {
    overflow: hidden;
  }
}

.container {
  width: 100%;
  max-width: $max-width-containers;
  margin: 0 auto;
  padding: 0 15px;

  &__narrow {
    max-width: 55%;

    @include respond-to-max("lg+") {
      max-width: 100%;
    }

    @include respond-to-max("ipAirPort") {
      max-width: 100%;
    }

    @include respond-to-max("md") {
      max-width: 100%;
    }
  }

  &--btn {
    text-align: center;
  }
}

#layout-content {
  overflow-y: hidden;

  @include respond-to-max("lg+") {
    overflow-x: hidden;
  }
}

.contentBlock {
  position: relative;
  z-index: 1;
}

.contentBlock {
  &__product {
    padding: 3em 0;

    .container {
      &--back {
        position: relative;
        margin-bottom: 5em;

        .backLink {
          position: absolute;
          left: 15px;
          top: 0;
          font-size: 2em;

          a {
            color: $darkGold;
          }
        }
      }
    }

    .product__hero {
      position: relative;
      margin-bottom: 3em;

      &--bg {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        height: 100%;
        overflow: hidden;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 0;
        overflow: hidden;

        svg {
          width: 100%;
          height: auto;
          -webkit-transform: translate(0%, -15%);
          transform: translate(0%, -15%);

          path {
            fill: rgba(255, 255, 255, 0.7);
          }
        }
      }

      &--image {
        position: relative;
        top: -50px;
        z-index: 1;

        img {
          width: 70%;
          height: auto;
          margin: 0 auto;
        }
      }
    }
  }
}

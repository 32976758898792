.contentBlock {
  &__flow {
    padding: 3em 0;
    text-align: center;

    .row {
      &--flex {
        @include flex-center;

        margin: 0 0 1em 0;

        .flow {
          width: 400px;

          &__content {
            text-align: center;
            background: $variflow;
            width: 325px;
            height: 325px;
            margin: 0 auto;

            @include flex-center;
            @include border-radius(100%);

            flex-wrap: wrap;

            .flow__text {
              font-size: 4.5em;

              p {
                line-height: 1em;
                margin: 0;
                position: relative;

                sup {
                  position: absolute;
                  right: -10%;
                  top: -45%;
                }
              }
            }

            .flow__image {
              &--container {
                @include flex-center;

                align-items: baseline;
                width: 170px;

                svg {
                  width: 55px;

                  path {
                    fill: $white;
                  }

                  @include respond-to-max("lg+") {
                    width: 40px;
                  }
                }
              }
            }
          }

          &__label {
            font-size: 1.5em;
            line-height: 1em;

            p {
              margin: 0;
            }
          }

          &--vari {
            .flow__content--container {
              .flow__image {
                .flow__image--container {
                  svg {
                    &.small-1 {
                      width: 40px;

                      @include respond-to-max("lg+") {
                        width: 30px;
                      }
                    }

                    &.small-2 {
                      width: 50px;

                      @include respond-to-max("lg+") {
                        width: 35px;
                      }
                    }
                  }
                }
              }
            }
          }

          &--slow {
            .flow__content {
              background: $slowflow;
            }
          }

          &--med {
            .flow__content {
              background: $medflow;
            }
          }

          &--fast {
            .flow__content {
              background: $fastflow;
            }
          }

          @include respond-to-max("ipAirLand") {
            width: 25%;

            .flow__content {
              width: 280px;
              height: 280px;
            }
          }

          @include respond-to-max("lg+") {
            .flow__content {
              width: 240px;
              height: 240px;

              .flow__content--container {
                .flow__text {
                  font-size: 3.5em;

                  p {
                    sup {
                      right: 0%;
                      top: -40%;
                    }
                  }
                }
              }
            }
          }

          @include respond-to-max("ipAirPort") {
            width: 50%;
          }

          @include respond-to-max("sm") {
            .flow__content {
              width: 180px;
              height: 180px;
              transform: scale(0.95);

              .flow__content--container {
                .flow__text {
                  font-size: 2.5em;

                  p {
                    sup {
                      right: 15%;
                    }
                  }
                }

                .flow__image {
                  &--container {
                    svg {
                      width: 30px;

                      &.small-1 {
                        width: 25px;
                      }

                      &.small-2 {
                        width: 27.5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        @include respond-to-max("ipAirPort") {
          flex-wrap: wrap;
        }
      }
    }

    @each $name, $BGcolor in $categoryColours {
      &.contentBlock__bg--#{$name} {
        .background {
          svg {
            path {
              fill: darken($BGcolor, 7.5);
            }
          }
        }
      }
    }
  }
}

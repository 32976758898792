.contentBlock {
  &__singleProduct {
    padding: 3em 0;
    text-align: center;

    .background {
      svg {
        width: 150%;
        height: auto;
      }
    }

    .productListing {
      .product {
        &__img {
          img {
            max-width: 100%;
            height: auto;
            margin: 0 auto;
          }
        }

        &__text {
          .text--block {
            h4 {
              margin-bottom: 1em;
            }

            p {
              margin-bottom: 1em;

              iframe {
                @include respond-to-max("ipAirLand") {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
        }

        &__button {
          .btn {
            margin-top: 0;
          }
        }
      }
    }

    &--FW {
      .container {
        max-width: 100%;
        padding: 0;

        .product {
          &__img {
            margin: 0 0 -10em 0;

            img {
              width: 100%;
            }

            @include respond-to-max("sm") {
              margin: 0;
            }
          }

          &__text {
            .text--block {
              max-width: 1440px;
              width: 60%;
              padding: 0 15px;
              margin: 0 auto;

              h4 {
                margin-bottom: 0.5em;
              }

              p {
                margin-bottom: 2em;

                iframe {
                  width: 100%;
                  height: auto;
                }
              }

              @include respond-to-max("sm") {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .rangeListing {
      @include flex-center;

      flex-wrap: wrap;
      align-items: flex-start;

      &__item {
        width: 25%;
        padding: 0 15px;

        .item__img {
          margin: 0 0 1em 0;

          img {
            width: 100%;
            max-width: 225px;
            height: auto;
            margin: 0 auto;
          }
        }

        .item__text {
          text-align: center;

          p {
            line-height: 1.2em;
            margin: 0 0 1em 0;
          }
        }

        @include respond-to-max("md") {
          width: 100%;
        }
      }
    }
  }
}

.carousel {
  background: $white;

  .glide__track {
    .glide__slides {
      .slide {
        margin-bottom: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.contentBlock {
  &__table {
    padding: 2em 0;

    .table-block-table {
      margin: 0;
    }

    .table-block {
      .u-width-container {
        padding: 0 15px;
      }
    }

    @include respond-to-max("sm") {
      overflow: scroll;
    }
  }
}

.table-block {
  position: relative;
  z-index: 1;

  .u-width-container {
    text-align: center;
  }
}

.table-block-table {
  width: 100%;
  margin-bottom: 20px;

  th,
  td {
    color: $ttgrey;
    padding: 10px;
    width: 20%;
    text-align: center;
    border-right: 2px solid $white;

    &:last-of-type {
      border-right: 0;
    }
  }

  tr:nth-child(odd) {
    background: $white;
  }

  tr:nth-child(even) {
    background: $bottleFeeding;
  }

  tr {
    &:first-of-type {
      background: $ttgrey;

      th {
        color: $white;
      }
    }
  }
}

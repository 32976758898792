.contentBlock {
  &__productListing {
    .contentBlock__intro {
      padding: 100px 0 200px 0;
      background: $darkGold;
      text-align: center;
    }

    .productListing {
      background: $white;

      .container {
        @include flex-center;

        flex-wrap: wrap;
        align-items: stretch;
      }

      &__item {
        @include border-radius(50px);

        overflow: hidden;

        .item__img {
          position: relative;
          overflow: hidden;

          img {
            width: 100%;
            height: auto;
          }
        }

        .item__text {
          @include respond-to("xl") {
            padding: 10em 0 10em 30px;
          }

          .btn {
            margin: 0;
          }
        }

        &--full {
          width: 100%;
          margin: 1%;
          margin-top: -150px;

          @include flex-center;

          .item__img,
          .item__text {
            flex: 0 0 50%;
          }

          @include respond-to("xl") {
            .item__img {
              height: 100%;

              img {
                width: auto;
                height: 150%;
                position: absolute;
                right: 0;
                top: -10%;
              }
            }
          }

          .item__text {
            padding-left: 30px;
          }

          h3 {
            font-size: 8.75em;

            @include respond-to-max("ipAirPort") {
              font-size: 6.5em;
            }
          }

          p {
            font-size: 1.5em;
          }

          @include respond-to-max("md") {
            display: block;
            //margin: 5% 0 0 0;

            .item__text {
              padding: 0 15px 30px 15px;
              text-align: center;
            }
          }
        }

        &--half {
          width: 48%;
          margin: 1%;
          padding: 2em 0 0 0;
          overflow: hidden;
          text-align: center;

          .item__text {
            padding: 0 2em;
          }

          @include respond-to("xl") {
            .item__img {
              height: 400px;

              img {
                width: 110%;
                height: auto;
                position: absolute;
                left: -5%;
                top: -2%;
              }
            }
          }

          h3 {
            font-size: 7.5em;

            @include respond-to-max("ipAirPort") {
              font-size: 5em;
            }
          }

          @include respond-to-max("md") {
            width: 100%;
            margin: 5% 0 0 0;
          }
        }

        &--third {
          width: 31.33%;
          margin: 1%;
          padding: 2em 0 0 0;
          overflow: hidden;
          text-align: center;

          .item__text {
            padding: 0 2em;
          }

          @include respond-to("xl") {
            .item__img {
              height: 330px;

              img {
                width: 110%;
                height: auto;
                position: absolute;
                left: -5%;
                top: 0;
              }
            }
          }

          @include respond-to-max("ipAirPort") {
            //padding: 2em 1em 0 1em;
          }

          @include respond-to-max("md") {
            //padding: 2em 2em 0 2em;
          }

          h3 {
            font-size: 5.625em;

            @include respond-to-max("ipAirPort") {
              font-size: 5em;
            }
          }

          p {
            font-size: 1.25em;
            line-height: 1.4em;

            @include respond-to-max("md") {
              font-size: 1.375em;
              line-height: 2em;
              margin: 0 0 2rem 0;
            }
          }

          @include respond-to-max("md") {
            width: 100%;
            margin: 5% 0 0 0;
          }
        }

        &:last-of-type {
          @include respond-to-max("md") {
            margin-bottom: 5%;
          }
        }
      }
    }
  }
}

.lang-hong-kong-chinese {
  .contentBlock__productListing {
    .productListing__item {
      &--full {
        h3 {
          @include respond-to-max("ipAirPort") {
            font-size: 4.5em;
            line-height: 1.1;
          }
        }
      }

      &--half {
        h3 {
          @include respond-to-max("ipAirPort") {
            font-size: 4.5em;
            line-height: 1.1;
          }
        }
      }
    }
  }
}

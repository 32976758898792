.u-width-container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

img {
  display: block;
}

.image-text-container {
  max-width: 1440px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
}

.image-text-container-alt {
  flex-direction: row-reverse;
}

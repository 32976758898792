.contentBlock {
  &__pageListing {
    background: $white;
    padding: 0 0 5em 0;

    .pageListing {
      .container {
        @include flex-center;

        flex-wrap: wrap;
        align-items: stretch;

        .pageListing__item {
          @include border-radius(50px);

          position: relative;
          width: 48%;
          margin: 1%;
          padding: 4em 0 0 0;
          text-align: center;
          overflow: hidden;

          .text--block {
            h4 {
              margin: 0 0 1rem 0;
            }

            p {
              margin: 0;
              line-height: 1.2em;
              font-family: "Gotham Rounded Light", Arial, Helvetica, sans-serif;
            }
          }

          .item__img {
            overflow: hidden;
            height: 400px;
            position: relative;

            @include border-radius(0 0 50px 50px);

            img {
              width: 110%;
              height: auto;
              position: absolute;
              left: -5%;
              top: 0;
            }
          }

          @include respond-to-max("md") {
            width: 100%;
            margin: 5% 0 0 0;
          }
        }
      }
    }
  }
}

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";

body {
  font-family: "Gotham Rounded", arial, sans-serif;
  font-size: 16px;
  color: $ttgrey;
  overflow-x: hidden;
}

// Base settings for headers, OL, UL

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "outboundTT", arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-size: 3.75em;
  line-height: 1.2em;
  font-family: "Gotham Rounded Bold", arial, sans-serif;

  .hero--standard & {
    font-family: "outboundTT", arial, sans-serif;
    font-size: 8.125em;
    line-height: 0.8em;
    margin: 0 0 0.5em 0;

    @include respond-to-max("sm") {
      font-size: 6em;
    }
  }

  .contentBlock__textBlock & {
    font-family: "outboundTT", arial, sans-serif;
    font-size: 8.125em;
    line-height: 1em;
    margin: 0 0 0.25em 0;
  }
}

h2 {
  font-size: 1.7rem;

  .hero--standard & {
    font-family: "Gotham Rounded", arial, sans-serif;
    font-size: 1.75em;
    margin: 0 0 1em 0;
    //color: $gold;
  }

  .contentBlock__textBlock & {
    font-family: "Gotham Rounded", arial, sans-serif;
    font-size: 2em;
    line-height: 1.2em;
    margin: 0 0 1em 0;
  }
}

h3 {
  font-size: 8.125em;
  line-height: 0.8em;
  margin: 0 0 3rem 0;
  //font-family: "Gotham Rounded Bold", Arial, Helvetica, sans-serif;

  @include respond-between("ipAirPort", "mobLand") {
    font-size: 6rem;
  }

  @include respond-to-max("ipAirPort") {
    font-size: 6em;
  }

  @include respond-to-max("md") {
    font-size: 5em;
    margin: 0 0 2rem 0;
  }

  .text & {
    font-family: "Gotham Rounded", arial, sans-serif;
    font-size: 2.45em;
    margin: 0 0 1rem 0;
    line-height: 1.2em;
  }

  .contentBlock__textBlock & {
    font-family: "Gotham Rounded", arial, sans-serif;
    font-size: 1.875em;
    line-height: 1.2em;
    margin: 0 0 1em 0;
  }
}

h4 {
  font-size: 1.75em;
  line-height: 1.2em;
  font-family: "Gotham Rounded", arial, sans-serif;
  margin: 0 0 2rem 0;

  &.bold {
    font-family: "Gotham Rounded Bold", Arial, Helvetica, sans-serif;
    font-size: 2em;
  }

  .contentBlock__fullWidth--shallow & {
    text-shadow: 0 3px 6px #00000029;
  }

  .contentBlock__textBlock & {
    font-family: "Gotham Rounded", arial, sans-serif;
    font-size: 1.75em;
    line-height: 1.2em;
    margin: 0 0 1em 0;
  }

  &.h1 {
    font-family: "outboundTT", arial, sans-serif;
    font-size: 8.125em;
    line-height: 0.8em;
    margin: 0 0 0.5em 0;
  }
}

h5 {
  font-size: 1.2rem;

  .contentBlock__textBlock & {
    font-family: "Gotham Rounded", arial, sans-serif;
    font-size: 1.625em;
    line-height: 1.2em;
    margin: 0 0 1em 0;
  }
}

h6 {
  .contentBlock__textBlock & {
    font-family: "Gotham Rounded", arial, sans-serif;
    font-size: 1.5em;
    line-height: 1.2em;
    margin: 0 0 1em 0;
  }
}

h6,
p {
  font-size: 1.375em;
  line-height: 1.6em;
  margin: 0 0 2rem 0;

  sup {
    font-size: 0.6rem;
    position: relative;
    top: -10px;
    margin-right: 10px;
  }

  .contentBlock__fullWidth--shallow & {
    text-shadow: 0 3px 6px #00000029;
    font-family: "Gotham Rounded Book", Arial, Helvetica, sans-serif;
  }

  .hero--standard & {
    @include respond-to-max("sm") {
      margin: 0;
    }
  }

  @include respond-to-max("sm") {
    font-size: 1em;
  }
}

p {
  a {
    color: $ttgrey;
  }
}

p.bold-weight {
  font-weight: 600;
}

p.lighter-weight {
  font-family: "Gotham Rounded Light", arial, sans-serif;
}

ol {
  list-style: decimal;
  list-style-position: inside;

  li {
    font-family: "Gotham Rounded Light", arial, sans-serif;
    font-size: 1.375rem;
    font-weight: 200;
    margin: 0 0 15px;
    text-align: left;
    line-height: 2.2rem;
    padding: 0 0 0 2em;
    text-indent: -2em;
    list-style-type: inherit;
    counter-increment: item;

    &::marker {
      font-weight: bold;
    }

    // &::before {
    //   display: inline-block;
    //   width: 1em;
    //   padding-right: 0.5em;
    //   font-weight: bold;
    //   text-align: right;
    //   content: counter(item) ".";
    // }

    .contentBlock__textBlock & {
      font-family: "Gotham Rounded Book", Arial, Helvetica, sans-serif;
    }
  }
}

ul {
  list-style: disc;
  list-style-position: inside;

  li {
    font-family: "Gotham Rounded Light", arial, sans-serif;
    font-size: 1.375rem;
    font-weight: 200;
    margin: 0 0 15px;
    text-align: left;
    line-height: 2.2rem;

    .contentBlock__textBlock & {
      font-family: "Gotham Rounded Book", Arial, Helvetica, sans-serif;
    }
  }
}

.remove-list-style {
  list-style: none;

  li {
    margin-bottom: 0;
  }
}

.textAligned {
  &__center {
    text-align: center;
  }
}

.text {
  &__navy {
    color: $ttgrey;
  }
}

hr {
  border-color: $ttgrey;
}

// Chinese only
// .lang-hong-kong-chinese {
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     font-family: "Gotham Rounded Bold", arial, sans-serif;
//   }

//   h1 {
//     .hero--standard & {
//       font-family: "Gotham Rounded Bold", arial, sans-serif;
//     }
//   }

//   .h1 {
//     font-family: "Gotham Rounded Bold", arial, sans-serif;
//   }
// }

.lang-hong-kong-chinese {
  h4 {
    // font-size: 4.5em;
    line-height: 1.3;
  }

  h3 {
    // font-size: 4.5em;
    line-height: 1.1;
  }
}
